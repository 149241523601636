import "./index.css";
import { useRef, useState, useEffect, useCallback } from "react";
import { Button, Flex, message } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getFeeds } from "../../../../services/supabaseClient";
import { shuffleArray } from "../../../../utils/service";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import Reviewer from "./User";
import UserLoading from "./UserLoading";
import DividerTitle from "../DividerTitle";
const PAGE_SIZE = 10;

const TopReviewers = () => {
  const { isMobileView } = useWindowSize();

  const [loading, setLoading] = useState(true);
  const [reviewers, setReviewers] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const { error, data } = await getFeeds(1, PAGE_SIZE);
      if (error) {
        message.error(error.message);
      } else {
        const owers = [];

        data.forEach((p) => {
          if (p.post) {
            const index = owers.findIndex((e) => e.id === p.post.owner.id);
            if (index === -1) {
              owers.push({
                ...p.post.owner,
                postId: p.post.id,
                totalNewPosts: 1,
              });
            } else {
              owers[index].totalNewPosts = owers[index].totalNewPosts + 1;
            }
          }
        });

        setReviewers(shuffleArray(owers));
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  //scroll handling
  const containerRef = useRef(null);
  const [isScrolledToStart, setIsScrolledToStart] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

  const maxScrollLeft = isMobileView ? 20 : 100;
  const stepScroll = isMobileView ? 150 : 200;

  const handleScroll = () => {
    const container = containerRef.current;
    const isAtStart = container.scrollLeft > maxScrollLeft;
    const isAtEnd = container.scrollLeft + container.offsetWidth >= container.scrollWidth;

    setIsScrolledToStart(isAtStart);
    setIsScrolledToEnd(isAtEnd);
  };

  const scrollLeft = () => {
    containerRef.current.scrollBy({ left: -stepScroll, behavior: "smooth" });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({ left: stepScroll, behavior: "smooth" });
  };

  const btnSize = isMobileView ? "small" : "middle";

  return (
    <>
      {loading && (
        <>
          <DividerTitle title="Recently active users" />

          <Flex
            ref={containerRef}
            onScroll={handleScroll}
            justify="start"
            gap={isMobileView ? 24 : 12}
            className="vfra-top-reviewers-container">
            {Array.from({ length: isMobileView ? 4 : 8 }).map((_, i) => (
              <UserLoading key={i} />
            ))}
          </Flex>
        </>
      )}

      {!loading && (
        <Flex vertical className="Vfra-top-reviewers-parent-container">
          <DividerTitle title="Recently active users" />

          {isScrolledToStart && (
            <Button
              size={btnSize}
              className="vfra-top-reviewers-prev-btn"
              shape="circle"
              onClick={scrollLeft}
              icon={<LeftOutlined />}
            />
          )}

          <Flex
            ref={containerRef}
            onScroll={handleScroll}
            justify="start"
            gap={isMobileView ? 6 : 12}
            className={`vfra-top-reviewers-container ${
              isScrolledToStart ? "vfra-top-reviewers-container-before" : ""
            }`}>
            {reviewers.map((user) => {
              return <Reviewer key={user.id} data={user} />;
            })}
          </Flex>

          {!isScrolledToEnd && (
            <Button
              size={btnSize}
              className="vfra-top-reviewers-next-btn"
              shape="circle"
              onClick={scrollRight}
              icon={<RightOutlined />}
            />
          )}
        </Flex>
      )}
    </>
  );
};

export default TopReviewers;
